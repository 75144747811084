<template>
  <el-main>
    <!-- 搜索 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="订单编号：">
        <el-input size="small" placeholder="请输入订单编号" v-model="form.order_no"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker value-format="timestamp" v-model="form.time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" @selection-change="selectionChange" ref="table">
      <template template slot="empty">
        <No />
      </template>
      <el-table-column prop="order_no" label="订单编号" align="center" width="200"></el-table-column>
      <el-table-column prop="classify_name" label="订阅时价" align="center">
        <template>
          444
          <el-popover placement="top-start" width="200" trigger="hover">
            <div>
              <div>666</div>
              <div>666</div>
            </div>
            <i class="el-icon-warning-outline primary" slot="reference"></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="total_price" label="时价价格" align="center"></el-table-column>
      <el-table-column prop="discount" label="会员折扣" align="center"></el-table-column>
      <el-table-column prop="discount_price" label="折后价格" align="center"></el-table-column>
      <el-table-column prop="amount" label="支付金额" width="140" align="center"></el-table-column>
      <el-table-column prop="pay_integral" label="支付积分" align="center"></el-table-column>
      <el-table-column prop="create_time" width="160" label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ getDateformat(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

  <script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      form: {
        page: 1,
        row: 10,
        order_no: '',
        time: '',
      },
      total: 0,
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.form.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.form = {
        page: 1,
        row: 10,
        order_no: '',
        time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getList();
      } else {
        this.form.page = val;
        this.getList();
      }
    },
    getList() {
      let data = Object.assign({}, this.form);
      if (data.time) {
        data.start_time = data.time[0] / 1000;
        data.end_time = data.time[1] / 1000;
        delete data.time;
      }
      this.$axios.post(this.$api.push.currentprice.orderList, data).then((res) => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat,
  },
};
</script>

  <style lang="less" scoped>
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;

  /deep/ .el-input__inner {
    border-color: #fff;
  }

  /deep/ .el-input__inner:focus {
    border-color: #409eff;
  }
}

.el-main {
  background: #fff;
}
.primary {
  color: #409eff;
  font-size: 18px;
  transform: translateY(2px);
}
</style>

